/*******************************
         Site Overrides
*******************************/

.ui.primary.buttons .button:hover, 
.ui.primary.button:hover,
.ui.primary.buttons .button, 
.ui.primary.button,
.ui.button {
  background: #8cBd3f;
  color: #fff;
}

.ui.secondary.buttons .button:hover, 
.ui.secondary.button:hover,
.ui.secondary.buttons .button, 
.ui.secondary.button {
	background: #595240;
	color: #fff;
}