/*******************************
         Site Overrides
*******************************/

.ui.menu .item {
  color: #fff;
}
.ui.secondary.menu .active.item{
  background: #595240;
  color: #fff;
}
.ui.secondary.menu .active.item:hover,
.ui.secondary.menu .dropdown.item:hover,
.ui.secondary.menu .link.item:hover,
.ui.secondary.menu a.item:hover {
  background: #8cBd3f;
  color: #fff;
}