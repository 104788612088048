/*******************************
         Site Overrides
*******************************/

.ui.input > input {
	border-radius: 10px;
	border-width: 2px;
	border-style: solid;
	color: #a8a8a8;
	font-size: 21px;
}

.ui.input.focus > input,
.ui.input > input:focus  {
	border-radius: 10px;
}