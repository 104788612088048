/*******************************
         Site Overrides
*******************************/

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"],
.ui.form input[type="url"] {
	border-radius: 10px;
	border-width: 2px;
	border-style: solid;
	color: #a8a8a8;
	font-size: 21px;
}

.ui.form textarea:focus,
.ui.form input:not([type]):focus, 
.ui.form input[type="date"]:focus, 
.ui.form input[type="datetime-local"]:focus, 
.ui.form input[type="email"]:focus, 
.ui.form input[type="number"]:focus, 
.ui.form input[type="password"]:focus, 
.ui.form input[type="search"]:focus, 
.ui.form input[type="tel"]:focus, 
.ui.form input[type="time"]:focus, 
.ui.form input[type="text"]:focus, 
.ui.form input[type="file"]:focus, 
.ui.form input[type="url"]:focus,
.ui.input.focus > input,
.ui.input > input:focus  {
  border-color: #8cBd3f !important;
	border-radius: 10px;
}

/*--------------------
      Autofilled
---------------------*/

.ui.form .field.field input:-webkit-autofill,
.ui.form .field.field input:-webkit-autofill:focus,
.ui.form .error.error input:-webkit-autofill {
  border-color: #595240 !important;
	box-shadow: 0px 0px 0px 100px #fff inset !important;
}