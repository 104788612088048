/*******************************
        User Overrides
*******************************/

.ui.selection.dropdown {
  border-radius: 20px;
  border-color: #707070;
}

.ui.selection.active.dropdown:hover .menu,
.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown:hover,
.ui.selection.dropdown:focus,
.ui.selection.active.dropdown{
  border-color: #525940;
}